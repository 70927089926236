.text-center {
  text-align: center;
}

body {
  //line-height: 0 !important;
  font-family: "OpenSans-Light";
  //font-weight: 100;
}

@font-face {
  font-family: "RobotoCondensed-Light";
  src: local("RobotoCondensed-Light"),
    url(./fonts/roboto-condensed/RobotoCondensed-Light.ttf)
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url(./fonts/Open-Sans/OpenSans-VariableFont_wdth,wght.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans-Bold"),
    url(./fonts/Open-Sans/static/OpenSans-Bold.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "OpenSans-Medium";
  src: local("OpenSans-Medium"),
    url(./fonts/Open-Sans/static/OpenSans-Medium.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "OpenSans-Light";
  src: local("OpenSans-Light"),
    url(./fonts/Open-Sans/static/OpenSans-Light.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "RobotoCondensed-Bold";
  src: local("RobotoCondensed-Bold"),
    url(./fonts/roboto-condensed/RobotoCondensed-Bold.ttf)
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "RobotoCondensed-Regular";
  src: local("RobotoCondensed-Regular"),
    url(./fonts/roboto-condensed/RobotoCondensed-Regular.ttf)
      format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
